<template>
    <div class="estadoItem">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-dialog v-model="modalEstadoItem" max-width="500px" persistent>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            <span class="text-h5">{{ tituloEstadoItem }}</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-form ref="frmEstadoItem" v-model="validoEstadoItem" lazy-validation>
                                <v-text-field v-model="editEstadoItem.nombre" :rules="reglaNombreEstadoItem"
                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable required>
                                </v-text-field>
                                <v-text-field v-model="editEstadoItem.alias" :rules="reglaAliasEstadoItem"
                                    prepend-inner-icon="mdi-format-color-text" label="Alias" outlined clearable
                                    required>
                                </v-text-field>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" class="mr-4" @click="cancelarEstadoItem">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :disabled="!validoEstadoItem" color="success" class="mr-4"
                                        @click="guardarEstadoItem">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalEliminarEstadoItem" max-width="550px">
                <v-card>
                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                        <v-icon x-large left color="error">
                            mdi-alert-octagon-outline
                        </v-icon>
                        ¿Estas seguro que deceas eliminar este campo?
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cerrareliminarEstadoItem">
                            Cancelar
                        </v-btn>
                        <v-btn color="success" @click="confirmarElimEstadoItem">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- tabla -->
            <tabla :header="headersEstadoItem" :body="estadosItems" tituloTabla="ESTADOS ITEMS" :accionAgregar="true"
                :exportar="true" @recargar="listarEstadoItem" @agregar="agregarEstadoItem"
                @actualizar="editarEstadoItem" @eliminar="eliminarEstadoItem" />
            <!-- tabla fin -->
        </v-card>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../components/view/Tabla";
    export default {
        name: "estadoItem",
        components: {
            tabla,
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,
            municipios: [],

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables estadoItem 
            UidEstadoItem: null,
            buscarEstadoItem: '',
            validoEstadoItem: true,
            modalEstadoItem: false,
            modalEliminarEstadoItem: false,
            headersEstadoItem: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre corto',
                    align: 'start',
                    value: 'alias',
                },
                {
                    text: 'Nombre / Estado Item',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            estadosItems: [],
            reglaNombreEstadoItem: [
                v => !!v || 'El nombre del estado es obligatorio.'
            ],
            reglaAliasEstadoItem: [
                v => !!v || 'El alias del estado es obligatorio.'
            ],
            editedEstadoItemIndex: -1,
            editEstadoItem: {
                nombre: '',
                alias: ''
            },
            defaultEstadoItem: {
                nombre: '',
                alias: ''
            },
            // fin variables estadoItem
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal estadoItem 
            tituloEstadoItem() {
                return this.editedEstadoItemIndex === -1 ? 'Nuevo Estado' : 'Editar Estado'
            },
        },

        watch: {
            // modal estadoItem
            modalEstadoItem(val) {
                val || this.cancelarEstadoItem()
            },
            modalEliminarEstadoItem(val) {
                val || this.cerrareliminarEstadoItem()
            },
            // fin modal estadoItem
        },

        created() {
            // listar estadosItems
            this.listarEstadoItem()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD paises 
            async listarEstadoItem() {
                try {
                    await fetch(`${this.url_api}/estado_item`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.estadosItems = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarEstadoItem() {
                this.modalEstadoItem = true
            },

            editarEstadoItem(estado) {
                this.editedEstadoItemIndex = this.estadosItems.indexOf(estado)
                this.editEstadoItem = Object.assign({}, estado)
                this.modalEstadoItem = true
                this.UidEstadoItem = estado.Uid;
            },

            eliminarEstadoItem(estado) {
                this.editedEstadoItemIndex = this.estadosItems.indexOf(estado)
                this.editEstadoItem = Object.assign({}, estado)
                this.modalEliminarEstadoItem = true
                this.UidEstadoItem = estado.Uid;
            },

            async confirmarElimEstadoItem() {
                try {
                    await fetch(`${this.url_api}/estado_item/${this.UidEstadoItem}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.estadosItems.splice(this.editedEstadoItemIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrareliminarEstadoItem();
            },

            cancelarEstadoItem() {
                this.$refs.frmEstadoItem.reset()
                this.$refs.frmEstadoItem.resetValidation()
                this.modalEstadoItem = false
                this.$nextTick(() => {
                    this.editEstadoItem = Object.assign({}, this.defaultEstadoItem)
                    this.editedEstadoItemIndex = -1
                })
            },

            cerrareliminarEstadoItem() {
                this.modalEliminarEstadoItem = false
                this.$nextTick(() => {
                    this.editEstadoItem = Object.assign({}, this.defaultEstadoItem)
                    this.editedEstadoItemIndex = -1
                })
            },

            async guardarEstadoItem() {
                if (this.$refs.frmEstadoItem.validate()) {
                    if (this.editedEstadoItemIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/estado_item/${this.UidEstadoItem}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editEstadoItem)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.estadosItems[this.editedEstadoItemIndex], this
                                            .editEstadoItem);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidEstadoItem = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/estado_item`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editEstadoItem)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarEstadoItem();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarEstadoItem()
                }
            },
            // fin CRUD estadosItems
        },
    }
</script>